/* tslint:disable */
/* eslint-disable */
/**
 * passport
 * passport接口文档
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppRequestSmsCheckSmsCodeRequest } from '../models';
// @ts-ignore
import { AppRequestSmsSendBabybusSmsCodeRequest } from '../models';
// @ts-ignore
import { ErrorSchema } from '../models';
/**
 * AppModelSmsSmsCodesApi - axios parameter creator
 * @export
 */
export const AppModelSmsSmsCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 验证短信验证码
         * @param {AppRequestSmsCheckSmsCodeRequest} [appRequestSmsCheckSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsCheckSmsCodePost: async (appRequestSmsCheckSmsCodeRequest?: AppRequestSmsCheckSmsCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sms/check_sms_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestSmsCheckSmsCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {AppRequestSmsSendBabybusSmsCodeRequest} [appRequestSmsSendBabybusSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendBabybusSmsCodePost: async (appRequestSmsSendBabybusSmsCodeRequest?: AppRequestSmsSendBabybusSmsCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sms/send_babybus_sms_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestSmsSendBabybusSmsCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\Sms\\SmsCodesController@userSendBabybusSmsCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendBabybusUserSmsCodePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sms/send_babybus_user_sms_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppModelSmsSmsCodesApi - functional programming interface
 * @export
 */
export const AppModelSmsSmsCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppModelSmsSmsCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 验证短信验证码
         * @param {AppRequestSmsCheckSmsCodeRequest} [appRequestSmsCheckSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest?: AppRequestSmsCheckSmsCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {AppRequestSmsSendBabybusSmsCodeRequest} [appRequestSmsSendBabybusSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest?: AppRequestSmsSendBabybusSmsCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\Sms\\SmsCodesController@userSendBabybusSmsCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsSendBabybusUserSmsCodePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsSendBabybusUserSmsCodePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppModelSmsSmsCodesApi - factory interface
 * @export
 */
export const AppModelSmsSmsCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppModelSmsSmsCodesApiFp(configuration)
    return {
        /**
         * 
         * @summary 验证短信验证码
         * @param {AppRequestSmsCheckSmsCodeRequest} [appRequestSmsCheckSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest?: AppRequestSmsCheckSmsCodeRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {AppRequestSmsSendBabybusSmsCodeRequest} [appRequestSmsSendBabybusSmsCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest?: AppRequestSmsSendBabybusSmsCodeRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\Sms\\SmsCodesController@userSendBabybusSmsCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendBabybusUserSmsCodePost(options?: any): AxiosPromise<string> {
            return localVarFp.apiSmsSendBabybusUserSmsCodePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppModelSmsSmsCodesApi - object-oriented interface
 * @export
 * @class AppModelSmsSmsCodesApi
 * @extends {BaseAPI}
 */
export class AppModelSmsSmsCodesApi extends BaseAPI {
    /**
     * 
     * @summary 验证短信验证码
     * @param {AppRequestSmsCheckSmsCodeRequest} [appRequestSmsCheckSmsCodeRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelSmsSmsCodesApi
     */
    public apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest?: AppRequestSmsCheckSmsCodeRequest, options?: AxiosRequestConfig) {
        return AppModelSmsSmsCodesApiFp(this.configuration).apiSmsCheckSmsCodePost(appRequestSmsCheckSmsCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送短信验证码
     * @param {AppRequestSmsSendBabybusSmsCodeRequest} [appRequestSmsSendBabybusSmsCodeRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelSmsSmsCodesApi
     */
    public apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest?: AppRequestSmsSendBabybusSmsCodeRequest, options?: AxiosRequestConfig) {
        return AppModelSmsSmsCodesApiFp(this.configuration).apiSmsSendBabybusSmsCodePost(appRequestSmsSendBabybusSmsCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\Sms\\SmsCodesController@userSendBabybusSmsCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelSmsSmsCodesApi
     */
    public apiSmsSendBabybusUserSmsCodePost(options?: AxiosRequestConfig) {
        return AppModelSmsSmsCodesApiFp(this.configuration).apiSmsSendBabybusUserSmsCodePost(options).then((request) => request(this.axios, this.basePath));
    }
}
