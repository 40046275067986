/* tslint:disable */
/* eslint-disable */
/**
 * passport
 * passport接口文档
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiUserUsersDelete200Response } from '../models';
// @ts-ignore
import { AppModelUserUsers } from '../models';
// @ts-ignore
import { AppRequestUserUsersChangePasswordRequest } from '../models';
// @ts-ignore
import { AppRequestUserUsersGetSmsAgentCodeRequest } from '../models';
// @ts-ignore
import { AppRequestUserUsersPatchRequest } from '../models';
// @ts-ignore
import { AppRequestUserUsersRegisterRequest } from '../models';
// @ts-ignore
import { AppResponseUserUsersGetSmsAgentCodeResponse } from '../models';
// @ts-ignore
import { AppResponseUserUsersInfoResponse } from '../models';
// @ts-ignore
import { BbcloudBabyApiRequestsBatchDeletionRequest } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesBatchDeletionResponse } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesCountResponse } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesTransitionByStateResponse } from '../models';
// @ts-ignore
import { ErrorSchema } from '../models';
/**
 * AppModelUserUsersApi - axios parameter creator
 * @export
 */
export const AppModelUserUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 修改用户密码
         * @param {AppRequestUserUsersChangePasswordRequest} [appRequestUserUsersChangePasswordRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangePasswordPost: async (appRequestUserUsersChangePasswordRequest?: AppRequestUserUsersChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestUserUsersChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@getStatistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetStatisticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户个人信息
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserInfoGet: async (accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户登录历史
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginHistoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/login_history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户注册
         * @param {AppRequestUserUsersRegisterRequest} [appRequestUserUsersRegisterRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost: async (appRequestUserUsersRegisterRequest?: AppRequestUserUsersRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestUserUsersRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@syncUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSyncUserPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sync_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户群晖Token
         * @param {string} url 
         * @param {string} clientId 
         * @param {number} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSynologyTokenGet: async (url: string, clientId: string, reset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('apiUserSynologyTokenGet', 'url', url)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiUserSynologyTokenGet', 'clientId', clientId)
            const localVarPath = `/api/user/synology_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (reset !== undefined) {
                localVarQueryParameter['reset'] = reset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户客户端权限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/userClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@userFrontInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserFrontInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/userFrontInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户客户端视图权限
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserPermissionsViewsGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiUserUserPermissionsViewsGet', 'clientId', clientId)
            const localVarPath = `/api/user/userPermissionsViews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersCountGet: async (groupBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersDelete: async (bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bbcloudBabyApiRequestsBatchDeletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersGet: async (q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserUsersIdDelete', 'id', id)
            const localVarPath = `/api/user/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserUsersIdGet', 'id', id)
            const localVarPath = `/api/user/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取短信代理验证码
         * @param {number} id 
         * @param {AppRequestUserUsersGetSmsAgentCodeRequest} [appRequestUserUsersGetSmsAgentCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdGetSmsAgentCodePost: async (id: number, appRequestUserUsersGetSmsAgentCodeRequest?: AppRequestUserUsersGetSmsAgentCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserUsersIdGetSmsAgentCodePost', 'id', id)
            const localVarPath = `/api/user/users/{id}/get_sms_agent_code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestUserUsersGetSmsAgentCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdOperationsLogsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserUsersIdOperationsLogsGet', 'id', id)
            const localVarPath = `/api/user/users/{id}/operations_logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestUserUsersPatchRequest} [appRequestUserUsersPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdPatch: async (id: number, appRequestUserUsersPatchRequest?: AppRequestUserUsersPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserUsersIdPatch', 'id', id)
            const localVarPath = `/api/user/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestUserUsersPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersSchemaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersStatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersTransitionsByStateGet: async (state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('apiUserUsersTransitionsByStateGet', 'state', state)
            const localVarPath = `/api/user/users/transitions_by_state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppModelUserUsersApi - functional programming interface
 * @export
 */
export const AppModelUserUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppModelUserUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 修改用户密码
         * @param {AppRequestUserUsersChangePasswordRequest} [appRequestUserUsersChangePasswordRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest?: AppRequestUserUsersChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@getStatistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetStatisticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetStatisticsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户个人信息
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserInfoGet(accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseUserUsersInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserInfoGet(accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户登录历史
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginHistoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户注册
         * @param {AppRequestUserUsersRegisterRequest} [appRequestUserUsersRegisterRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRegisterPost(appRequestUserUsersRegisterRequest?: AppRequestUserUsersRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRegisterPost(appRequestUserUsersRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@syncUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSyncUserPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSyncUserPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户群晖Token
         * @param {string} url 
         * @param {string} clientId 
         * @param {number} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSynologyTokenGet(url: string, clientId: string, reset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSynologyTokenGet(url, clientId, reset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户客户端权限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@userFrontInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserFrontInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserFrontInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户客户端视图权限
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserPermissionsViewsGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserPermissionsViewsGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersCountGet(groupBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersCountGet(groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesBatchDeletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUserUsersDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersGet(q, page, pageSize, orderBy, select, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppModelUserUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取短信代理验证码
         * @param {number} id 
         * @param {AppRequestUserUsersGetSmsAgentCodeRequest} [appRequestUserUsersGetSmsAgentCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersIdGetSmsAgentCodePost(id: number, appRequestUserUsersGetSmsAgentCodeRequest?: AppRequestUserUsersGetSmsAgentCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseUserUsersGetSmsAgentCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersIdGetSmsAgentCodePost(id, appRequestUserUsersGetSmsAgentCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersIdOperationsLogsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersIdOperationsLogsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestUserUsersPatchRequest} [appRequestUserUsersPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersIdPatch(id: number, appRequestUserUsersPatchRequest?: AppRequestUserUsersPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppModelUserUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersIdPatch(id, appRequestUserUsersPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersSchemaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersSchemaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersStatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersStatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUsersTransitionsByStateGet(state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesTransitionByStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUsersTransitionsByStateGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppModelUserUsersApi - factory interface
 * @export
 */
export const AppModelUserUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppModelUserUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary 修改用户密码
         * @param {AppRequestUserUsersChangePasswordRequest} [appRequestUserUsersChangePasswordRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest?: AppRequestUserUsersChangePasswordRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@getStatistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetStatisticsGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserGetStatisticsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户个人信息
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserInfoGet(accessToken?: string, options?: any): AxiosPromise<AppResponseUserUsersInfoResponse> {
            return localVarFp.apiUserInfoGet(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户登录历史
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginHistoryGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserLoginHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户注册
         * @param {AppRequestUserUsersRegisterRequest} [appRequestUserUsersRegisterRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost(appRequestUserUsersRegisterRequest?: AppRequestUserUsersRegisterRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserRegisterPost(appRequestUserUsersRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@syncUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSyncUserPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserSyncUserPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户群晖Token
         * @param {string} url 
         * @param {string} clientId 
         * @param {number} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSynologyTokenGet(url: string, clientId: string, reset?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserSynologyTokenGet(url, clientId, reset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户客户端权限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserClientsGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUserClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\User\\UsersController@userFrontInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserFrontInfoGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUserFrontInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户客户端视图权限
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserPermissionsViewsGet(clientId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUserPermissionsViewsGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersCountGet(groupBy?: string, options?: any): AxiosPromise<BbcloudBabyApiResponsesCountResponse> {
            return localVarFp.apiUserUsersCountGet(groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: any): AxiosPromise<BbcloudBabyApiResponsesBatchDeletionResponse> {
            return localVarFp.apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: any): AxiosPromise<ApiUserUsersDelete200Response> {
            return localVarFp.apiUserUsersGet(q, page, pageSize, orderBy, select, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdDelete(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdGet(id: number, options?: any): AxiosPromise<AppModelUserUsers> {
            return localVarFp.apiUserUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取短信代理验证码
         * @param {number} id 
         * @param {AppRequestUserUsersGetSmsAgentCodeRequest} [appRequestUserUsersGetSmsAgentCodeRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdGetSmsAgentCodePost(id: number, appRequestUserUsersGetSmsAgentCodeRequest?: AppRequestUserUsersGetSmsAgentCodeRequest, options?: any): AxiosPromise<AppResponseUserUsersGetSmsAgentCodeResponse> {
            return localVarFp.apiUserUsersIdGetSmsAgentCodePost(id, appRequestUserUsersGetSmsAgentCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdOperationsLogsGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUsersIdOperationsLogsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestUserUsersPatchRequest} [appRequestUserUsersPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersIdPatch(id: number, appRequestUserUsersPatchRequest?: AppRequestUserUsersPatchRequest, options?: any): AxiosPromise<AppModelUserUsers> {
            return localVarFp.apiUserUsersIdPatch(id, appRequestUserUsersPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersSchemaGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUsersSchemaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersStatesGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUsersStatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUsersTransitionsByStateGet(state: string, options?: any): AxiosPromise<BbcloudBabyApiResponsesTransitionByStateResponse> {
            return localVarFp.apiUserUsersTransitionsByStateGet(state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppModelUserUsersApi - object-oriented interface
 * @export
 * @class AppModelUserUsersApi
 * @extends {BaseAPI}
 */
export class AppModelUserUsersApi extends BaseAPI {
    /**
     * 
     * @summary 修改用户密码
     * @param {AppRequestUserUsersChangePasswordRequest} [appRequestUserUsersChangePasswordRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest?: AppRequestUserUsersChangePasswordRequest, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserChangePasswordPost(appRequestUserUsersChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\User\\UsersController@getStatistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserGetStatisticsGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserGetStatisticsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户个人信息
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserInfoGet(accessToken?: string, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserInfoGet(accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户登录历史
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserLoginHistoryGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserLoginHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户注册
     * @param {AppRequestUserUsersRegisterRequest} [appRequestUserUsersRegisterRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserRegisterPost(appRequestUserUsersRegisterRequest?: AppRequestUserUsersRegisterRequest, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserRegisterPost(appRequestUserUsersRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\User\\UsersController@syncUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserSyncUserPost(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserSyncUserPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户群晖Token
     * @param {string} url 
     * @param {string} clientId 
     * @param {number} [reset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserSynologyTokenGet(url: string, clientId: string, reset?: number, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserSynologyTokenGet(url, clientId, reset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户客户端权限
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUserClientsGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUserClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\User\\UsersController@userFrontInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUserFrontInfoGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUserFrontInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户客户端视图权限
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUserPermissionsViewsGet(clientId: string, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUserPermissionsViewsGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 统计
     * @param {string} [groupBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersCountGet(groupBy?: string, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersCountGet(groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量删除
     * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 列表读取
     * @param {string} [q] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderBy] 
     * @param {string} [select] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersGet(q, page, pageSize, orderBy, select, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersIdDelete(id: number, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 详情读取
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取短信代理验证码
     * @param {number} id 
     * @param {AppRequestUserUsersGetSmsAgentCodeRequest} [appRequestUserUsersGetSmsAgentCodeRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersIdGetSmsAgentCodePost(id: number, appRequestUserUsersGetSmsAgentCodeRequest?: AppRequestUserUsersGetSmsAgentCodeRequest, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersIdGetSmsAgentCodePost(id, appRequestUserUsersGetSmsAgentCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 操作日志读取
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersIdOperationsLogsGet(id: number, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersIdOperationsLogsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新
     * @param {number} id 
     * @param {AppRequestUserUsersPatchRequest} [appRequestUserUsersPatchRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersIdPatch(id: number, appRequestUserUsersPatchRequest?: AppRequestUserUsersPatchRequest, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersIdPatch(id, appRequestUserUsersPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 视图脚本读取
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersSchemaGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersSchemaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 模型状态读取
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersStatesGet(options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersStatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定状态可用操作读取
     * @param {string} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelUserUsersApi
     */
    public apiUserUsersTransitionsByStateGet(state: string, options?: AxiosRequestConfig) {
        return AppModelUserUsersApiFp(this.configuration).apiUserUsersTransitionsByStateGet(state, options).then((request) => request(this.axios, this.basePath));
    }
}
