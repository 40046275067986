import { createRouter, createWebHistory } from 'vue-router'

export const routes = [
  {
    name: 'LoginLayout',
    component: () => import(/* webpackChunkName: "LoginLayout" */ '../layouts/LoginLayout'),
    redirect: '/oauth2/login',
    children: [
      {
        path: '/oauth2/login',
        name: 'UserLogin',
        meta: { title: '用户登录' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/login/Login')
      },
      {
        path: '/dingtalk_login',
        name: 'DingtalkLogin',
        meta: { title: '钉钉登录' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/login/DingtalkLogin')
      },
      {
        path: '/register',
        name: 'UserRegister',
        meta: { title: '用户注册' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/login/Register')
      },
      {
        path: '/wechat_login',
        name: 'WechatLogin',
        meta: { title: '微信登录' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/wechat/Login.vue')
      },
      {
        path: '/wechat_loading',
        name: 'WechatLoading',
        meta: { title: '微信登录中' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/wechat/WechatLoading.vue')
      },
      {
        path: '/wechat_bind_phone',
        name: 'WechatBindPhone',
        meta: { title: '绑定手机号' },
        component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/wechat/WechatBindPhone.vue')
      }
    ]
  },
  {
    path: '/oauth2/confirm_auth',
    name: 'ConfirmAuth',
    meta: { title: '确认授权' },
    component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/login/ConfirmAuth')
  },
  {
    path: '/user/center',
    name: 'UserCenter',
    meta: { title: '用户中心' },
    component: () => import(/* webpackChunkName: "LoginLayout" */ '../views/user/UserCenter')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: { title: '404页面' },
    component: () => import(/* webpackChunkName: "contacts" */ '../views/404')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
