import router from './index.js'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { message, Modal } from 'ant-design-vue'
import { domTitle, getCookie, setDocumentTitle } from '@/utils'
import { api } from '@/utils/api'
import { omit } from 'lodash'
// 非登陆白名单
const whiteList = ['UserLogin', 'UserForget', 'ResetPassword']
router.onError(error => {
  // 前端更新的时候，自动刷新网页
  const pattern = /Loading (.*)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    console.log('系统已升级，正在刷新当前网页...')
    location.reload()
  }
})

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.title) {
    setDocumentTitle(`${to.meta.title} - ${domTitle}`)
  }
  const token = getCookie(ACCESS_TOKEN)
  if (to.matched.length === 0) {
    // 没有匹配到路由跳转到404页面
    return next('/404')
  } else if (whiteList.includes(to.name)) {
    // 在免登录白名单，直接进入
    return next()
  } else if (!token) {
    // 没有登陆，先登录
    // return next({ name: 'UserLogin' })
  }
  // 通常用于路由监听当中，处理路由前进、后退不能销毁确认对话框的问题
  Modal.destroyAll()

  if (to.name === 'WechatBindPhone') {
    if (!to.query.openid) {
      message.warn('请先进行微信授权')
      return goLogin()
    } else {
      try {
        const res = await api.apiOauth2HasWechatUserGet(undefined, to.query.openid)
        if (res) {
          message.warn('当前用户已绑定手机号')
          return goLogin()
        }
      } catch (error) {
        return goLogin()
      }
    }
  }

  // 跳转到登录页
  function goLogin() {
    return next({
      name: 'WechatLogin',
      query: omit(to.query, ['code', 'openid']),
      replace: true
    })
  }

  return next()
})

router.afterEach(() => {})
