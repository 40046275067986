import axios from 'axios'
// import { DefaultApi } from '@/api/passport/api.ts'
// import { Configuration } from '@/api/passport/configuration.ts'
// import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Modal } from 'ant-design-vue'
import notification from 'ant-design-vue/es/notification'
import debounce from 'lodash/debounce'
import Router from '@/router'
import store from '@/store'
import { getCookie } from '@/utils/index'
import {
  AppModelOauth2ClientsApiFactory,
  AppModelSmsSmsCodesApiFactory,
  AppModelUserUsersApiFactory,
  Configuration
} from '../api/passport'

const requestArrError = (error, descriptionVNode) => {
  if (typeof error.response.data === 'string') {
    descriptionVNode.push(error.response.data)
  } else if (Array.isArray(error.response.data)) {
    Object.keys(error.response.data).forEach((key, index) => {
      for (const i in error.response.data[key]) {
        if (error.response.data[key][i].indexOf('\n') >= 0) {
          error.response.data[key][i] = error.response.data[key][i]
            .split('\n')
            .map((value, index) => {
              if (index !== 0) {
                value = '\b\b\b\b\b' + value
                return value
              }
              return value
            })
            .join('\n')
        }
        descriptionVNode.push(`${index + 1}、${error.response.data[key]}`)
      }
    })
  } else if (typeof error.response.data === 'object') {
    descriptionVNode.push(error.response.data.message)
  }
}
const loginOutNotice = function () {
  notification.warning({
    message: '请登录',
    description: '未登录或授权已失效，请重新登录。'
  })
  if (getCookie(ACCESS_TOKEN)) {
    store.dispatch('passiveLogout')
  }
  let loginRoute = { name: 'UserLogin' }
  loginRoute = { ...loginRoute, query: Router.currentRoute.value.query }
  Router.push(loginRoute)
}
const redirectHandler = function (data) {
  if (data.redirect) {
    if (data.redirect === 'login') {
      loginOutNotice()
    } else {
      Router.push(data.redirect)
    }
  }
}
const errorHandler = error => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 400) {
      // fix： code 60121 存在unicode编码
      const description = JSON.stringify(data).includes('60121') ? '找不到该用户' : data.message ? data.message : data
      notification.warning({
        message: '提示',
        description
      })
    }

    if (error.response.status === 403) {
      noAccessNoticeDebounce(data)
    }

    if (error.response.status === 404) {
      noResourcesNoticeDebounce(data)
    }
    if (error.response.status === 405) {
      notMethodAllowedHttpNoticeDebounce(data)
    }
    if (error.response.status === 422) {
      let msg = ''
      if (data.message) {
        try {
          msg = JSON.stringify(JSON.parse(data.message))
        } catch (e) {
          msg = data.message
        }
      } else {
        msg = JSON.stringify(data)
      }

      notification.warning({
        message: '参数不合法',
        description: msg
      })
    }
    if (error.response.status === 500) {
      serviceErrorNoticeDebounce(error)
    }
    if (error.response.status === 504) {
      notification.warning({
        message: '提示',
        description: '网络不稳定，请稍候再试'
      })
    }
    if (error.response.status === 401) {
      // 登录过期、未登录
      loginOutNoticeDebounce()
    }
  } else {
    if (!error.message) {
      return Promise.reject(error)
    }
  }
  return Promise.reject(error)
}
const noAccessNotice = data => {
  let msg = data.message || '无权限操作'
  notification.warning({
    message: '提示',
    description: msg
  })
}
const serviceErrorNotice = error => {
  Modal.error({
    title: '服务错误',
    content: () => {
      const children = []
      requestArrError(error, children)
      return <div style={{ 'white-space': 'pre-line' }}>{children}</div>
    }
  })
}
const noResourcesNotice = () => {
  notification.warning({
    message: '提示',
    description: '该资源未找到'
  })
}
const notMethodAllowedHttpNotice = () => {
  notification.warning({
    message: '提示',
    description: '该对象未启用当前方法'
  })
}
export const loginOutNoticeDebounce = debounce(loginOutNotice, 1000)
const noAccessNoticeDebounce = debounce(noAccessNotice, 1000)
const serviceErrorNoticeDebounce = debounce(serviceErrorNotice, 1000)
const noResourcesNoticeDebounce = debounce(noResourcesNotice, 1000)
const notMethodAllowedHttpNoticeDebounce = debounce(notMethodAllowedHttpNotice, 1000)

axios.interceptors.request.use(config => {
  return config
})
axios.interceptors.response.use(response => {
  return response.data
}, errorHandler)

const config = new Configuration({
  basePath: process.env.VUE_APP_API_BASE_URL,
  accessToken: () => {
    return getCookie(ACCESS_TOKEN)
  },
  baseOptions: {
    timeout: 600000
  }
})
export const api = {
  ...AppModelOauth2ClientsApiFactory(config),
  ...AppModelUserUsersApiFactory(config),
  ...AppModelSmsSmsCodesApiFactory(config)
}
export default axios
