import { createApp } from 'vue'
import store from './store'
import router from './router'
import './router/hook'
import { fullLoadIcon } from './core/lazy_icon'
import * as Sentry from '@sentry/vue'

// Antd
import App from './App.vue'
import './theme/bb-theme.less'
import Antd from 'ant-design-vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
dayjs.extend(require('dayjs/plugin/relativeTime'))

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://a99671e8379a4406a8918261dc4d8c66@sentry.bbcloud.babybus.com/11'
})

app.use(router)
app.use(store)
app.use(Antd)

fullLoadIcon(app)
app.mount('#app')
