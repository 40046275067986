// 全量导入 icons，首屏会多大概1m的大小，屁点大
// 主要是这样太方便了，手动导入太麻烦了
import * as icons from '@ant-design/icons-vue'
// import * as iconPark from '@icon-park/vue-next'
import '@icon-park/vue-next/styles/index.css'

// 可以直接使用下面两个库的所有icon， 无需import，直接使用
// 页可以使用BBIcon name配置
//https://next.antdv.com/components/icon-cn/
//https://iconpark.oceanengine.com/official

export const fullLoadIcon = app => {
  for (const name in icons) {
    app.component(name, icons[name])
  }
  // for (const name in iconPark) {
  //   app.component(name, iconPark[name])
  // }
}

// import {
//   TabletOutlined
// } from '@ant-design/icons-vue'
//
// export const fullLoadIcon = (app) => {
//   app.component('TabletOutlined',TabletOutlined)
// }
